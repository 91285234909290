import React, { useEffect } from "react";
import { SubmissionSort } from "../../Helpers";
import { useActions, useAppState } from "../../overmind";
const TableSort = ({ review }) => {
    const state = useAppState();
    const actions = useActions();
    useEffect(() => {
        return () => {
            actions.setSubmissionSort(SubmissionSort.Approved);
            actions.setAscending(true);
            actions.clearSubmissionFilter();
        };
    }, []);
    const handleChange = (sort) => {
        actions.setSubmissionSort(sort);
    };
    const toggleIndividualSubmissions = () => {
        actions.setIndividualSubmissionsView(!state.individualSubmissionView);
    };
    return (React.createElement("div", { className: "p-1 mb-2 bg-dark text-white d-flex flex-row" },
        React.createElement("div", { className: "d-inline-flex flex-row justify-content-center" },
            React.createElement("div", { className: "p-2" },
                React.createElement("span", null, "Sort by:")),
            React.createElement("div", { className: `${state.sortSubmissionsBy === SubmissionSort.Approved ? "font-weight-bold" : ""} p-2`, role: "button", onClick: () => handleChange(SubmissionSort.Approved) }, "Approved"),
            React.createElement("div", { className: `${state.sortSubmissionsBy === SubmissionSort.Score ? "font-weight-bold" : ""} p-2`, role: "button", onClick: () => handleChange(SubmissionSort.Score) }, "Score"),
            React.createElement("div", { className: "p-2", role: "button", onClick: () => actions.setAscending(!state.sortAscending) },
                React.createElement("i", { className: state.sortAscending ? "icon fa fa-caret-down" : "icon fa fa-caret-down fa-rotate-180" }))),
        React.createElement("div", { className: "d-inline-flex flex-row" },
            React.createElement("div", { className: "p-2" }, "Show:"),
            React.createElement("div", { className: "p-2", role: "button", onClick: () => actions.setSubmissionFilter("teachers") }, state.submissionFilters.includes("teachers") ? React.createElement("del", null, "Teachers") : "Teachers"),
            React.createElement("div", { className: "p-2", role: "button", onClick: () => actions.setSubmissionFilter("approved") }, state.submissionFilters.includes("approved") ? React.createElement("del", null, "Graded") : "Graded"),
            review ?
                React.createElement("div", { className: "p-2", role: "button", onClick: () => actions.setSubmissionFilter("released") }, state.submissionFilters.includes("released") ? React.createElement("del", null, "Released") : "Released")
                : null),
        React.createElement("div", { className: "d-inline-flex flex-row" },
            React.createElement("div", { className: "p-2", role: "button", onClick: toggleIndividualSubmissions }, state.individualSubmissionView ? "Individual" : "Group"))));
};
export default TableSort;
